import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import SimplePageBlock from "../components/Blocks/SimplePageBlock"
import styled from "styled-components"
import colors from "../styles/colors"
import breakpoints from "../styles/breakpoints"
import { DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from "../components/Layout/Layout"
import fonts from "../styles/fonts"

const StyledSimplePageBlock = styled(SimplePageBlock)`
  padding-top: calc(4vmax + ${DESKTOP_HEADER_HEIGHT}px);
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 6.6vmax;

  @media (max-width: ${breakpoints.md}) {
    padding-top: calc(4vmax + ${MOBILE_HEADER_HEIGHT}px);
  }

  color: ${colors.black};
  ul {
    list-style-type: disc;
    margin 0.5em 0;
    padding-inline-start: 40px;
  }
  ol {
    list-style-type: decimal;
    margin 0.5rem 0;
    padding-inline-start: 40px;
  }
  p {
    margin: 0.5rem 0;
    font-size: 1rem;
  }
  a {
    color: ${colors.royalBlue};
  }
  b {
    ${fonts.workSansBold}
    font-size: 1rem;
  }
  h2 {
    color: ${colors.royalBlue};
    line-height: 1.104;
    ${fonts.workSansBold};
    margin: 2rem 0;
  }
`

const NotFoundPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulSimplePage

  let sharingImage = false
  if (page.socialMediaImage) {
    sharingImage = page.socialMediaImage.file.url
  }

  return (
    <Layout dark bg={colors.lightGrey}>
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
        path={path}
        image={sharingImage}
      />
      <StyledSimplePageBlock heading={page.pageTitle} content={page.content} />
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query NotFoundQuery {
    contentfulSimplePage(slug: { eq: "404" }) {
      ...simplePageFragment
    }
  }
`
