import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import ELink from "./ExternalLink"
import { ctaButtonStyle } from "./Styles"

const ExternalLink = styled(ELink)`
  ${ctaButtonStyle};
`

const InternalLink = styled(Link)`
  ${ctaButtonStyle};
`

const CtaButton = (props) => {
  const { url, label, blue } = props
  return url.includes("https://www.eatigc.com") ? (
    <InternalLink
      to={url.substring(22)}
      className={props.className}
      blue={blue}
    >
      {label}
    </InternalLink>
  ) : (
    <ExternalLink href={url} className={props.className} blue={blue}>
      {label}
    </ExternalLink>
  )
}

export default CtaButton
