import React from "react"
import Text from "./Text"
import Hero from "./Hero"

const SimplePageBlock = (props) => {
  const { heading, content, className, hero } = props

  return (
    <div className={className}>
      <h2>{heading}</h2>
     
      <Text content={content} references={content.references}/>
    </div>
  )
}

export default SimplePageBlock
